import { v4 as uuidv4 } from "uuid";

export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false;

export const getUrlParameter = (param) => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const getUuid = () => {
  // Check if the uuid is already in local storage
  if (typeof window !== "undefined") {
    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      return uuid;
    }

    // if not, generate a new uuid
    const uuid_v4 = uuidv4();
    // save the uuid to local storage
    localStorage.setItem("uuid", uuid_v4);
    return uuid_v4;
  }
};

export const scrollToSection = (target, offset) => {
  const headerHeight =
    document.querySelector("header")?.getBoundingClientRect().height || 0;
  const targetElement = document.querySelector(target);

  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop - headerHeight - (offset || 0),
      behavior: "smooth", // Optional smooth scrolling
    });
  }
};

export const lockScroll = () => {
  const paddingRight = window.innerWidth - document.body.offsetWidth;
  const top = window.scrollY;

  document.body.setAttribute("data-state", "locked");
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.paddingRight = `${paddingRight}px`;
  document.body.style.top = -top + "px";
  document.body.style.right = 0;
  document.body.style.bottom = 0;
  document.body.style.left = 0;
};

export const resetScroll = () => {
  const currentScrollY = document.body.style.top
    .replace("-", "")
    .replace("px", "");

  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.paddingRight = "";
  document.body.style.top = "";
  document.body.style.right = "";
  document.body.style.bottom = "";
  document.body.style.left = "";
  window.scrollTo({ top: currentScrollY, behavior: "instant" });
  document.body.removeAttribute("data-state");
};

export const writeSessionStorage = (key, value) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem(key, value);
  }
};

export const readSessionStorage = (key) => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(key);
  }
};
