import React, { createContext, useState, useRef, useEffect } from "react";
import { writeSessionStorage, readSessionStorage } from '@utils/utils';

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const [uuid, setUuid] = useState(null);
  const headerRef = useRef(null);
  const stickyIsiRef = useRef(null);
  const footerRef = useRef(null);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const bannerState = readSessionStorage('showSisBanner') !== 'false';
    setShowBanner(bannerState);
  }, []);

  const closeBanner = () => {
    writeSessionStorage('showSisBanner', 'false');
    setShowBanner(false);
  }

  const store = {
    uuid,
    setUuid,
    headerRef,
    stickyIsiRef,
    footerRef,
    showSignUpModal,
    setShowSignUpModal,
    isHeaderVisible,
    setIsHeaderVisible,
    showBanner,
    closeBanner,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
