import React from "react";
import { AppProvider } from "./src/components/AppContext";

import "@fontsource/passion-one/700.css";
import "@fontsource-variable/urbanist"; // Supports weights 100-900

import "@styles/cookies.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
